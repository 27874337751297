<template>
    <div>
        <b-row class="my-3">
            <b-col sm="8">
                <b>CATEGORIES</b>
            </b-col>
        </b-row>

        <b-row class="mt-3" no-gutters>
            <b-col sm="10" md="5">
                <b-form-group label="Category" label-for="Category"
                    description="Select ' - Others - ' if the category is NOT available">
                    <v-select name="Category" class="style-chooser" label="text" :options="categoryOptions"
                        :reduce="(category) => category.value" v-model="category">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                No results found for
                                <em>
                                    <strong>{{ search }}</strong>
                                </em>
                            </template>
                            <em :style="{ opacity: 0.5 }" v-else>
                                Start typing to search for a category
                            </em>
                        </template>
                    </v-select>
                    <span v-show="errors.has('Category')" class="help-block">{{
                        errors.first('Category')
                    }}</span>
                </b-form-group>
            </b-col>
            <b-col sm="1" v-if="category !== 'Others'">
                <b-button id="addCategory" class="add-category" variant="success" @click="onAddCategory">Add</b-button>
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2" v-show="category !== 'Others'">
                <b-input-group align="right" class="pagination" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>
        <b-row no-gutters v-if="category === 'Others'">
            <b-col sm="10" md="5" class="mb-4">
                <b-form-input name="Other Category" type="text" v-model="otherCategory" v-validate="{
                    regex: categoryRegex,
                }" maxlength="50" />
                <span v-show="errors.has('Other Category')" class="help-block">{{
                    errors.first('Other Category')
                }}</span>
            </b-col>
            <b-col sm="1">
                <b-button id="addOtherCategory" class="add-other-category" variant="success"
                    @click="onAddCategory">Add</b-button>
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2">
                <b-input-group align="right" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="form.categories" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(action)="row">
                <b-button size="sm" v-b-tooltip.hover.top="'Remove'" variant="danger" @click="onRemoveCategory(row.item.id)"
                    class="mr-1">
                    <i class="fa fa-trash-o"></i>
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import _ from 'lodash';

export default {
    name: 'asset-type-categories',
    props: {
        form: {
            type: Object,
            required: true,
        },
        allAssetTypeCategories: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            category: null,
            otherCategory: '',
            fields: [
                {
                    key: 'name',
                    label: 'Category',
                    sortable: true,
                },
                'action',
            ],

            categoryOptions: [],

            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    watch: {
        category: function (newVal) {
            if (newVal && newVal.length > 0) {
                this.category = ValidationUtil.removeExcessWhiteSpace(newVal);
            }
        },
        otherCategory: function (newVal) {
            if (newVal && newVal.length > 0) {
                this.otherCategory = ValidationUtil.removeExcessWhiteSpace(newVal);
            }
        }
    },
    computed: {
        categoryRegex() {
            return /^[ÑñA-Za-z0-9&",.!() '-]{1,50}$/;
        },
        totalRows() {
            return this.form.categories.length
        }
    },
    mounted() {
        this.categoryOptions = DropDownItemsUtil.retrieveAssetCategoryItems(this.allAssetTypeCategories, true);

        // reset fields
        this.category = null;
        this.otherCategory = '';
    },
    methods: {
        onAddCategory() {
            let category = this.category === 'Others' ? this.otherCategory : this.category;

            if (!this.validateCategory(category)) {
                return;
            }

            this.form.categories.push({
                id: category.toLowerCase(),
                name: category
            });

            // reset field
            this.category = null;
            this.otherCategory = '';
        },
        validateCategory(category) {
            let isValid = true;

            if (!category || category.length <= 0) {
                this.$toaster.warning('Category is required.');
                isValid = false;
            } else if (this.hasExistingCategory(category)) {
                this.$toaster.warning('Category already exists on this asset type.');
                isValid = false;
            } else if (!this.categoryRegex.test(category)) {
                this.$toaster.warning('Invalid category format. Must contain lower and upper case A-Z characters only.');
                isValid = false;
            }

            return isValid;
        },
        hasExistingCategory(category) {
            return ValidationUtil.objectHasField('id', category, this.form.categories);
        },

        onRemoveCategory(categoryId) {
            let index = _.findIndex(this.form.categories, (item) => {
                return item.id == categoryId;
            });

            if (index >= 0) {
                this.form.categories = _.filter(this.form.categories, (item) => {
                    return item.id !== categoryId;
                });
            }
        },
    }
}

</script>

<style>
.other-category {
    margin-top: 30px;
    margin-left: 10px;
}

.add-category {
    margin-top: 29px;
    margin-left: 10px;
}

.add-other-category {
    margin-left: 10px;
}

.pagination {
    margin-top: 29px;
}
</style>